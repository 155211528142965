<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neuer Benutzer">
      <user-form
        :user="user"
        submit-label="Neuen Benutzer anlegen"
        :form-submitted="saveUser"
        mode="new"
        :super-admin-mode="true"
      ></user-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import UserForm from "@/components/forms/UserForm"
import SuperAdminUsersRepository from "@/repositories/super_admin/users_repository.js"

export default {
  metaInfo: {
    title: "Neuer Benutzer"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    UserForm
  },
  data() {
    return {
      user: {
        region_roles: {}
      }
    }
  },
  methods: {
    async saveUser(user) {
      await SuperAdminUsersRepository.invite(user)
      this.$router.push({ name: "SuperAdminUsersPage" })
    }
  }
}
</script>
